const { KEYWORDS, PROFILES, CHANNELS } = require('../../constants/crawlRequests')

module.exports = {
  types: {
    [KEYWORDS]: 'Keywords',
    [PROFILES]: 'Profiles',
    [CHANNELS]: 'Telegram channels',
  },
  add: {
    [KEYWORDS]: 'Request keyword crawling. Multiple keywords can be added by separating them with commas.',
    [PROFILES]: 'Request profile crawling. Multiple profiles can be added by separating them with commas. Make sure to choose the correct profile type and ID type.',
    [CHANNELS]: 'Request Telegram channel crawling. Multiple channels can be added by separating them with commas.',
  },
  activeTooltip: {
    [PROFILES]: 'Profiles show as active when the crawler could discover them. If the profile is not active, it may not exist or the crawler was unable to find it.',
    [CHANNELS]: 'Channels show as active when the crawlers were able to discover them. If the channel is not active, it may not exist or the crawler was unable to find it.',
  },
}