<template>
  <q-btn
    size="15px"
    round
    flat
    class="delete-cell"
    icon="delete"
    @click="deleteRow"
  />
</template>

<script>
export default {
  components: {
  },
  props: {
    cell: Object,
  },
  methods: {
    async deleteRow () {
      this.$store.dispatch('crawlRequests/deleteRow', this.cell.row)
    },
  }
}
</script>

<style scoped lang="scss">
.delete-cell {
  &:hover {
    color: var(--color-error);
  }
}
</style>