<template>
  <div
    @click="$emit('update:model-value', !modelValue)"
    class="toggle"
    :class="{ on: modelValue }"
  >
    <div class="knob"></div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
  },
}
</script>

<style scoped lang="scss">
.toggle {
  background: var(--color-light-text);
  transition: background 0.3s;
  width: 3rem;
  height: 1.5rem;
  border-radius: 5rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.toggle.on {
  background: var(--color-primary);
}

.knob {
  background: var(--color-white);
  width: 1rem;
  height: 1rem;
  border-radius: 5rem;
  margin-left: 0.25rem;
  transition: margin-left 0.3s;
}

.on .knob {
  margin-left: calc(3rem - 1.25rem);
}
</style>