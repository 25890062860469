<template>
  <template v-if="!cell.row.active && !cell.row.crawling_status">
    N/A
  </template>
  <q-spinner
    v-else-if="value === undefined"
    color="our-primary"
    size="1.5rem"
  />
  <template v-else>
    {{ value.totalCount }}
    <span v-if="value.totalCount > 0">
      ({{ Math.round(value.percentChange * 100)}}%)
      <BaseIcon
        v-if="value.percentChange > 0"
        name="trending_up"
        color="green"
      />
      <BaseIcon
        v-else-if="value.percentChange < 0"
        name="trending_down"
        color="red"
      />
    </span>
  </template>
</template>

<script>
import BaseIcon from '../BaseIcon.vue'

export default {
  components: {
    BaseIcon,
  },
  props: {
    cell: Object,
    value: Object,
  },
}
</script>

<style scoped lang="scss">
</style>