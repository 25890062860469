<template>
  <div>
    <span>
      <span class="profile-container">
        <RotateArrow :rotate="profileOpen" color="var(--color-black)" />
        <div class="profile-icon">
          {{ user?.nickname?.slice(0, 2).toUpperCase() }}
        </div>
      </span>
    </span>
    <q-menu v-model="profileOpen" auto-close fit>
      <div class="modal-content">
        <div class="prof-subsection">
          <div class="profile-icon">
            {{ user.nickname.slice(0, 2).toUpperCase() }}
          </div>
          <div class="flex-column">
            <div
              class="text-body-4 text-weight-normal"
              style="margin-bottom:2px;"
            >{{ user.nickname }}</div>
            <div class="text-body-4 text-disabled user-email">
              {{ user.email }}
            </div>
          </div>
        </div>
        <hr>
        <div class="prof-subsection prof-footer text-tooltip text-primary">
          <router-link
            class="subsection-link"
            to="/account"
          >
            <div>Account settings</div>
          </router-link>
          <div @click="signout">Sign out</div>
        </div>
      </div>
    </q-menu>
  </div>
</template>

<script>
import RotateArrow from '@/components/RotateArrow'

export default {
  components: {
    RotateArrow,
  },
  data () {
    return {
      user: this.$auth0.user,
      profileOpen: false,
    }
  },
  methods: {
    signout () {
      this.$auth0.logout({ returnTo: 'https://openmeasures.io' })
    },
  },
}
</script>

<style scoped lang="scss">
.profile-container {
  display: flex;
  margin-right: 1rem;
  flex-direction: row-reverse;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.modal-content {
  min-width: 233px;
  border: 2px solid var(--color-black);
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 4px;
  font-family: var(--primary-font);
}

.router-link-active {
  text-decoration: none !important;
}

.prof-subsection {
  display: flex;
  padding: 16px 12px;
  flex-direction: row;
  align-items: center;
}

a {
  font-weight: bold;
  color: var(--color-purple);

  &.router-link-exact-active {
    color: gray;
  }
}

.prof-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-transform: uppercase;
  div {
    &:hover {
      cursor: pointer;
    }
    margin-bottom: 4px;
  }
  div:first-child {
    margin-bottom: 16px;
  }
}

.user-email {
  font-weight: 500;
}

.profile-icon {
  color: var(--color-white);
  padding: 4px 4px;
  margin-left: -4px;
  margin-right: 8px;
}

hr {
  border: 0;
  height: 1px;
  margin: 0;
  background-color: var(--color-black);
}

.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid var(--color-purple);
  border-radius: 50%;
  background-color: var(--color-purple);
  margin-right: 8px;
}

.svg-icon {
  height: 12px;
  width: 12px;
  color: var(--color-black);
  padding: 0 0 0 9px;
}

.text-tooltip {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 1px;
}
</style>