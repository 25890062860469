module.exports = {
  COMMUNITIES: 'communities',
  GAB: 'gab',
  FOURCHAN: '4chan',
  EIGHTKUN: '8kun',
  PARLER: 'parler',
  WIN: 'win',
  POAL: 'poal',
  TELEGRAM: 'telegram',
  GETTR: 'gettr',
  BITCHUTE_VIDEO: 'bitchute_video',
  BITCHUTE_COMMENT: 'bitchute_comment',
  BITCHUTE: 'bitchute',
  TIKTOK_VIDEO: 'tiktok_video',
  TIKTOK_COMMENT: 'tiktok_comment',
  TIKTOK: 'tiktok',
  WIMKIN: 'wimkin',
  MEWE: 'mewe',
  RUMBLE_VIDEO: 'rumble_video',
  RUMBLE_COMMENT: 'rumble_comment',
  RUMBLE: 'rumble',
  LBRY_VIDEO: 'lbry_video',
  LBRY_COMMENT: 'lbry_comment',
  LBRY: 'lbry',
  MINDS: 'minds',
  VK: 'vk',
  TRUTH_SOCIAL: 'truth_social',
  TRUTH_SOCIAL_CRAWL_REQUESTS: 'truthsocial',
  RUTUBE_VIDEO: 'rutube_video',
  RUTUBE_COMMENT: 'rutube_comment',
  RUTUBE: 'rutube',
  OK: 'ok',
  BLUESKY: 'bluesky',
  FEDIVERSE: 'fediverse'
}
