<template>
  <div class="hidden-text">
    <input
      v-if="canSee"
      :value="text"
      disabled="disabled"
      type="text"
      class="input-editable-text"
    >
    <div v-else class="input-editable-text">
      ***************************
    </div>
    <img
      v-if="canSee"
      @click="toggleSee"
      class="input-editable-icon"
      :src='noView'
    >
    <img
      v-else
      @click="toggleSee"
      class="input-editable-icon"
      :src="view"
    >
  </div>
</template>

<script>
import NoView from '@/assets/input-noview-icon.svg'
import View from '@/assets/input-icon.svg'

export default {
  props: {
    text: String,
    canSee: Boolean,
    toggleSee: Function,
  },
  data: function () {
    return {
      view: View,
      noView: NoView,
    }
  },
}
</script>

<style scoped lang="scss">
.hidden-text {
  display: flex;
  flex-direction: column;
}
.input-editable-text {
  font-family: var(--primary-font);
  color: graytext;
  padding: 12px;
  padding-right: 45px;
  border: 2px solid var(--color-light-text);
  &:disabled {
    background-color: var(--color-white);
  }
  &:hover {
    cursor: text;
  }
}
.input-editable-icon {
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  right: 37px;
  width: 24px;
  height: auto;
  padding-top: 16px;
}
</style>