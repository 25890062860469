<template>
  <div class="wrapper">
    <div class="left">
      <div class="label">
        {{ $t("setting.showChangepoint") }}
      </div>
      <BaseIcon name="info">
        <BaseTooltip>
          {{ $t("setting.changepointInfo") }}
        </BaseTooltip>
      </BaseIcon>
    </div>
    <BaseToggle
      :model-value="changepoint"
      @update:model-value="setChangepoint"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseTooltip from '@/components/BaseTooltip'
import BaseToggle from './BaseToggle.vue'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    BaseIcon,
    BaseTooltip,
    BaseToggle,
  },
  computed: {
    ...mapGetters(['changepoint'])
  },
  methods: {
    ...mapMutations(['setChangepoint'])
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
}

.left {
  flex: 1;
  display: flex;
}

.label {
  text-align: left;
  font-size: var(--fs-3);
  font-weight: 600;
  margin-right: 0.5rem;
}

.tooltip-content {
  font-family: var(--primary-font);
}
</style>
