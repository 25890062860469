<template>
  <BaseModal :model-value="value" @update:model-value="$emit('input', $event), $emit('update:value', $event)">
    <div class='tools-container'>
      <div class="title">
        {{ $t("toolPicker.modalTitle") }}
      </div>
      <div class="subtitle">
        {{ $t("toolPicker.modalSubtitle") }}
      </div>
      <div class="tool-buttons">
        <ButtonToolPicker
          v-for="tool in tools"
          @click="$emit('input', false), $emit('update:value', false)"
          :key="tool"
          :tool="tool"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ButtonToolPicker from './ButtonToolPicker'
import { TOOLS } from '@/helpers/searchQuery'

export default {
  components: {
    BaseModal,
    ButtonToolPicker
  },
  props: {
    value: Boolean
  },
  computed: {
    tools: () => TOOLS,
  }
}
</script>

<style scoped lang="scss">
.tools-container {
  color: var(--color-black);
  padding: 20px;
  max-width: 600px;

}

.tool-buttons {
  display: grid;
  grid-row-gap: var(--spacing-4);
  font-size: var(--fs-1);
}

.title,
.subtitle {
  text-align: center;
}

.title {
  font-family: var(--primary-font);
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.2;
}

.subtitle {
  font-family: var(--primary-font);
  color: var(--color-black);
  font-size: var(--fs-2);
  margin-top: 1rem;
  margin-bottom: 3rem;
}
</style>
