<template>
  <q-icon :name="name" :size="size" :color="color">
    <slot/>
  </q-icon>
</template>

<script>
export default {
  props: {
    name: String,
    size: String,
    color: String,
  }
}
</script>

<style scoped lang="scss"></style>
