module.exports = {
  TIMELINE: 'timeline',
  SEARCH: 'search',
  LINKS: 'links',
  ACTIVITY: 'activity',
  ACCOUNT: 'account',
  CRAWLREQUESTS: 'crawlrequests',
  MEDIA: 'media',
  NETWORK: 'network',
  HOME: 'home',
  DASHBOARD: 'dashboard',
}
