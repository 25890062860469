<template>
  <q-th :props="header">
    <ActiveHeader v-if="header.col.name === 'active'" :header="header"/>
    <SourceHeader v-else-if="header.col.name === 'source'" :header="header"/>
    <ActivityHeader v-else-if="header.col.name === 'activity'" :header="header"/>
    <template v-else>
      {{ header.col.label }}
    </template>
  </q-th>
</template>

<script>
import ActiveHeader from './ActiveHeader.vue'
import SourceHeader from './SourceHeader.vue'
import ActivityHeader from './ActivityHeader.vue'

export default {
  components: {
    ActiveHeader,
    SourceHeader,
    ActivityHeader,
  },
  props: {
    header: Object,
  },
}
</script>