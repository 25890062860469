<template>
  <div class="clickable" @click="onClick">
    {{ truncated }}
    <BaseTooltip>
      <template v-if="justClicked">
        Copied!
      </template>
      <template v-else>
        Click to copy: {{ value }}
      </template>
    </BaseTooltip>
  </div>
</template>

<script>
import BaseTooltip from '../BaseTooltip.vue'

export default {
  components: {
    BaseTooltip,
  },
  props: {
    maxLength: Number,
    value: [String, Number],
  },
  data () {
    return {
      justClicked: false,
    }
  },
  computed: {
    truncated () {
      const tooLong = this.value.length > this.maxLength

      if (tooLong) {
        return this.value.slice(0, this.maxLength) + '…'
      } else {
        return this.value
      }
    },
  },
  methods: {
    onClick () {
      window.navigator.clipboard.writeText(this.value)

      this.justClicked = true

      setTimeout(() => {
        this.justClicked = false
      }, 2000)
    },
  },
}
</script>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
}
</style>