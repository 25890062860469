<template>
  <q-dialog
    :maximized="!large"
    square
    :model-value="modelValue"
    :persistent='persistent'
    @update:model-value="$emit('input', $event), $emit('update:model-value', $event)"
  >
    <q-card class='base-modal-container'>
      <div class="inside">
        <div class="top-bar">
          <q-btn
            v-if='showCloseBtn'
            size="20px"
            round
            flat
            icon="close"
            @click="$emit('input', false), $emit('update:model-value', false)"
          />
        </div>
        <div class="slot">
          <slot></slot>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import omLogo from '@/assets/open-measures-logo-full.svg'
const { ENV, PRODUCTION } = require('@/constants/misc')

export default {
  props: {
    modelValue: Boolean,
    showCloseBtn: { type: Boolean, default: true },
    persistent: { type: Boolean, default: false },
    showLogo: Boolean,
  },
  data () {
    return {
      omLogo,
      large: false,
      env: ENV,
      showENV: ENV !== PRODUCTION
    }
  },
  methods: {
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    }
  },
  created () {
    this.updateLarge()
    window.addEventListener('resize', this.updateLarge)
  }
}
</script>

<style scoped lang="scss">
.base-modal-container {
  background-color: var(--color-off-white);
}
.inside {
  background-color: var(--color-off-white);
  color: var(--color-black);
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

img.om-logo {
  height: 22px;
}

div.env {
  padding-left: 10px;
  color: red;
}


.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding-left: 12pic;
  }
}

.slot {
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {

  .base-modal-container {
    width: 100vw;
    max-width: 100vw;
  }
}

@media (max-width: 650px) {
  .top-bar {
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 24px;
    }
  }
}
</style>
