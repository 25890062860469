const PRODUCTION = 'production'

module.exports = {
  PRODUCTION,
  PUBLIC_APP: false,
  ENV: (
    process.env.VUE_APP_ENV_DISPLAY ||
      process.env.NODE_ENV ||
      PRODUCTION
  ),
}
