<template>
  <div class="pagination">
    <span style="margin-right: var(--spacing-1)">
      Records per page:
    </span>
    <q-select
      borderless
      color="our-purple"
      popup-content-style="font-family: var(--primary-font)"
      :model-value="recordsPerPage"
      @update:model-value="$emit('update:records-per-page', $event)"
      :options="[15, 50, 100]"
    />
    <q-btn
      size="15px"
      round
      flat
      icon="chevron_left"
      :disabled="pages.isFirstPage"
      @click="pages.prevPage(), $emit('prevPage')"
    />
    Page {{ pages.pagination.page }} of {{ pages.pagesNumber }}
    <q-btn
      size="15px"
      round
      flat
      icon="chevron_right"
      :disabled="pages.isLastPage"
      @click="pages.nextPage(), $emit('nextPage')"
    />
  </div>
</template>

<script>
export default {
  props: {
    recordsPerPage: Number,
    pages: Object,
  },
  emits: [
    'prevPage',
    'nextPage',
    'update:records-per-page',
  ]
}
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  align-items: center;
}
</style>