<template>
  <div class="activity-header">
    {{ header.col.label }} ({{ activitySpan }})
    <q-btn
      size="10px"
      round
      flat
      icon="settings"
    >
      <q-popup-proxy
        ref="activityPopup"
        transition-show="scale"
        transition-hide="scale"
        anchor="center right"
        self="center left"
      >
        <div class="activity-popup">
          <q-radio
            v-for="time in [
              { val: 'day', label: 'Last 24 hours' },
              { val: 'week', label: 'Last week' },
              { val: 'month', label: 'Last month' },
              { val: 'year', label: 'Last year' },
            ]"
            :key="time.val"
            class="time-row"
            color="our-purple"
            :val="time.val"
            :label="time.label"
            :model-value="activitySpan"
            @update:model-value="setSpan"
          ></q-radio>
        </div>
      </q-popup-proxy>
    </q-btn>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapState,
  mapMutations,
} = createNamespacedHelpers('crawlRequests')

export default {
  props: {
    header: Object,
  },
  computed: {
    ...mapState(['activitySpan']),
  },
  methods: {
    ...mapMutations(['setActivitySpan']),
    setSpan (event) {
      this.setActivitySpan(event)
      this.$refs.activityPopup.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.activity-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-0);
}

.activity-popup {
  margin: var(--spacing-3);
  display: flex;
  flex-direction: column;
}

.time-row {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  font-family: var(--primary-font);
}
</style>