<template>
  <div class="source-cell">
    <WebsiteIcon :website="value" />
    {{ siteLabel }}
  </div>
</template>

<script>
import { siteLabel } from '../../helpers/crawlRequests'
import WebsiteIcon from '../WebsiteIcon.vue'

export default {
  components: {
    WebsiteIcon,
  },
  props: {
    value: String,
  },
  computed: {
    siteLabel () {
      return siteLabel(this.value)
    },
  }
}
</script>

<style scoped lang="scss">
.source-cell {
  display: flex;
  align-content: center;
  gap: var(--spacing-1);
}
</style>