<template>
  <q-toolbar class="toolbar">
    <q-toolbar-title class="title">
      <router-link :to="'/'" class="parent">
        <img :src='omLogo' class='om-logo' />
      </router-link>
    </q-toolbar-title>
    <q-space />

    <q-btn
      v-if="isTool"
      class='edit-query'
      label='Edit Query'
      unelevated
      dense
      color="purple"
      @click="$emit('toggleToolBar')"
      style='font-size: 10px; line-height: 13px; padding: 3px 10px;'
    />

    <div class="right-part">
      <div
        class="right-part-nav">
        <router-link
            class="subsection-link"
            to="/"
          >
          <ButtonInner :active="$route.name === HOME">
            <span class='top-bar-link'> Home </span>
          </ButtonInner>
        </router-link>
        <a href='https://docs.openmeasures.io/partners' target='_blank'>
          <ButtonInner>
            <span class='top-bar-link'>{{ $t("nav.docs") }}</span>
          </ButtonInner>
        </a>
      </div>
      <ProfileButton />
      <div class="right-part-lang">
        <LanguagePickerDropdown />
      </div>
    </div>
  </q-toolbar>
</template>

<script>
import ButtonInner from '@/components/input/ButtonInner'
import LanguagePickerDropdown from '@/components/input/LanguagePickerDropdown'
import ProfileButton from './ProfileButton.vue'
import omLogo from '@/assets/open-measures-logo-full.svg'
import { isTool } from '@/helpers/searchQuery'
const { HOME } = require('@/constants/tools')

export default {
  components: {
    ButtonInner,
    ProfileButton,
    LanguagePickerDropdown,
  },
  data () {
    return {
      omLogo,
      HOME,
    }
  },
  computed: {
    isTool () {
      return isTool(this.$route.name)
    },
  },
}
</script>

<style scoped lang="scss">
.subsection-link {
  color: var(--color-black);
}

.parent {
  display: flex;
  align-items: center;
}

.toolbar {
  background: var(--color-off-white);
  border-bottom: var(--border-width) solid var(--color-light-grey);

  .router-link-active {
    text-decoration: none !important;
  }

  .toolbar-back {
    font-size: 24px;
    font-weight: bold;
  }

  .om-logo {
    height: 22px;
  };

  .edit-query {
    display: none;
    margin-right: 2em;
    padding: 0 5px;
  }

  .right-part {
    display: flex;
    align-items: center;

    .right-part-nav {
      display: flex;
      align-items: center;

      > * {
        margin-right: 3rem;
      }
    }
    a {
      font-weight: bold;
      color: var(--color-purple);

      &.router-link-exact-active {
        color: gray;
      }
    }

    .api-button { cursor: pointer; }
  }

  .top-bar-link {
    font-size: 12px;
    &.active {
        border-bottom-color: var(--color-primary);
    }
  }
}

@media (max-width: 1000px) {
  .toolbar .edit-query {
    display: grid;
  }
}

@media (max-width: 860px) {
  .toolbar .top-bar-link {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .toolbar {
    .edit-query {
      margin-right: 5px;
    }

    .right-part {
      margin-right: 1rem;
      display: none;
    }
  }
}
</style>
