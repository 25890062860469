<template>
  <q-checkbox
    :model-value="modelValue"
    @update:model-value="$emit('update:model-value', $event)"
    color="our-black"
    class="base-checkbox"
    :class="{ 'checked': modelValue }"
  />
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
  }
}
</script>

<style lang="scss">
.base-checkbox {
  &.checked {
    .q-checkbox__bg {
      background: var(--color-blue) !important;
    }
  }

  .q-checkbox__bg {
    border-width: var(--border-width) !important;
  }

  .q-checkbox__svg {
    color: black !important;
  }
}
</style>