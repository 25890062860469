<template>
  <q-page :class="{ 'app-page': !onHome }">
    <div class="wrapper">
      <router-view name="page" v-slot="{ Component }" >
        <component :is="Component" />
      </router-view>
    </div>
  </q-page>
</template>

<script>
const { HOME } = require('@/constants/tools')

export default {
  computed: {
    onHome () {
      return this.$route.name === HOME
    },
  },
}
</script>

<style scoped lang="scss">
.app-page {
  background: var(--color-light-bg);
  color: var(--color-black);
  padding: 3rem;

  .wrapper {
    max-width: var(--spacing-max-content-width);
    margin: auto;

    min-height: 70vh;
  }
}

@media (max-width: 650px) {
  .app-page {
    padding: 3rem 0;
  }
}
</style>
