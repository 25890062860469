<template>
  <div>
    <div class="banner">
      {{ $t("home.banner") }}
    </div>
    <div class="text-title-3 spacing">
      {{ $t(`nav.${CRAWLREQUESTS}`) }}
    </div>
    <TypeTabs class="spacing" @newTab="newTab"/>
    <q-table
      separator="cell"
      flat
      bordered
      row-key="id"
      :dense="$q.screen.lt.md"
      :visibleColumns="visibleColumns"
      :columns="columns"
      :rows="rowData"
      :loading="loading"
      :pagination="pagination"
      @update:pagination="paginationState = $event"
    >
      <template v-slot:top-left>
        <TableTopLeft
          @adding="setLoading(true)"
          @added="newQuery()"
        />
      </template>
      <template v-slot:top-right>
        <q-input
          borderless
          dense
          debounce="1000"
          :model-value="searchTerm"
          @update:model-value="newSearchTerm"
          placeholder="Search"
        >
          <template v-slot:append>
            <BaseIcon name="search"/>
          </template>
          <BaseTooltip>
            Search for requested keywords
          </BaseTooltip>
        </q-input>
      </template>
      <template v-slot:header-cell="header">
        <TableHeader :header="header"/>
      </template>
      <template v-slot:body-cell="cell">
        <TableCell :cell="cell"/>
      </template>
      <template v-slot:bottom="pages">
        <div class="table-bottom">
          <Pagination
            :records-per-page="rowsPerPage"
            @update:records-per-page="setRowsPerPage"
            :pages="pages"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </div>
      </template>
    </q-table>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import router from '../router'
import { CRAWLREQUESTS } from '../constants/tools'
import { KEYWORDS, CHANNELS, PROFILES } from '../constants/crawlRequests'
import TableTopLeft from '../components/crawlRequests/TableTopLeft.vue'
import TypeTabs from '../components/crawlRequests/TypeTabs.vue'
import TableHeader from '../components/crawlRequests/TableHeader.vue'
import TableCell from '../components/crawlRequests/TableCell.vue'
import BaseIcon from '../components/BaseIcon.vue'
import BaseTooltip from '../components/BaseTooltip.vue'
import Pagination from '../components/crawlRequests/Pagination.vue'

const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers('crawlRequests')

export default {
  components: {
    TableTopLeft,
    TypeTabs,
    TableHeader,
    TableCell,
    BaseIcon,
    BaseTooltip,
    Pagination,
  },
  data () {
    return {
      CRAWLREQUESTS,
      paginationState: {},
    }
  },
  computed: {
    ...mapState(['loading', 'searchTerm', 'sites', 'activitySpan', 'rowsPerPage']),
    ...mapGetters(['type', 'isAdmin', 'rowData', 'activityData', 'totalRowCount']),
    pagination () {
      return {
        ...this.paginationState,
        rowsPerPage: this.rowsPerPage,
        rowsNumber: this.totalRowCount,
      }
    },
    visibleColumns () {
      if (this.isAdmin) {
        if (this.type === KEYWORDS) {
          return ['active', 'source', 'last_fetched', 'id', 'keyword', 'activity']
        } else if (this.type === PROFILES) {
          return ['active', 'source', 'last_fetched', 'id', 'profile', 'source_id', 'source_url', 'datatype']
        } else if (this.type === CHANNELS) {
          return ['media_active', 'active', 'last_fetched', 'channel', 'id', 'activity']
        }
      } else {
        if (this.type === KEYWORDS) {
          return ['delete', 'source', 'last_fetched', 'id', 'keyword', 'activity']
        } else if (this.type === PROFILES) {
          return ['delete', 'active', 'source', 'last_fetched', 'id', 'profile', 'source_id', 'source_url', 'datatype']
        } else if (this.type === CHANNELS) {
          return ['delete', 'active', 'last_fetched', 'channel', 'id', 'activity']
        }
      }

      return []
    },
    columns () {
      return [
        {
          name: 'delete',
          label: 'Delete',
          autoWidth: true, // this is not an actual col prop, but in TableCell we grab this value and apply it there
        },
        {
          name: 'media_active',
          label: 'Media Active',
          field: 'media_status',
          autoWidth: true,
        },
        {
          name: 'active',
          label: 'Crawl Active',
          field: col => col.active ?? col.crawling_status,
          autoWidth: true,
        },
        {
          name: 'source',
          label: 'Source',
        },
        {
          name: 'last_fetched',
          label: 'Last Fetched',
          field: col => col.last_fetched ?? col.updated_utc
        },
        {
          name: 'channel',
          label: 'Channel',
          field: 'username',
        },
        {
          name: 'id',
          label: 'ID',
        },
        {
          name: 'profile',
          label: 'Username',
        },
        {
          name: 'source_id',
          label: 'Source ID',
        },
        {
          name: 'source_url',
          label: 'Source URL',
        },
        {
          name: 'datatype',
          label: 'Datatype',
        },
        {
          name: 'keyword',
          label: 'Keyword',
        },
        {
          name: 'activity',
          label: 'Activity',
        }
      ].map(col => ({
        field: col.name,
        align: 'left',
        ...col,
      }))
    },
  },
  methods: {
    ...mapMutations(['setLoading', 'setSearchTerm', 'clearResults', 'setRowsPerPage']),
    ...mapActions(['query', 'queryTimeseries']),
    async newQuery (args) {
      await this.query(args)
      this.resetPagination()
    },
    newTab (newType) {
      this.clearResults()
      this.setSearchTerm('')

      this.newQuery({ type: newType })
    },
    newSearchTerm (newSearchTerm) {
      this.setSearchTerm(newSearchTerm)
      this.$router.replace({
        name: CRAWLREQUESTS,
        params: { type: this.type },
        query: { searchTerm: newSearchTerm },
      })
      this.newQuery()
    },
    prevPage () {
      this.paginationState.page--

      this.query({
        prevPage: true,
        page: this.paginationState.page,
      })
    },
    nextPage () {
      this.paginationState.page++

      this.query({
        nextPage: true,
        page: this.paginationState.page,
      })
    },
    resetPagination () {
      this.paginationState = {
        page: 1,
      }
    }
  },
  watch: {
    sites () {
      this.newQuery()
    },
    activitySpan () {
      this.queryTimeseries()
    },
    rowsPerPage () {
      this.newQuery()
    },
  },
  async created () {
    await router.isReady()
    const searchTerm = this.$route.query.searchTerm
    this.setSearchTerm(searchTerm)
    this.newQuery()
  }
}
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  background-color: var(--color-blue);
  color: #212529;
  text-align: center;
  padding: 10px 0;
  font-family: var(--primary-font);
  font-size: 15px;
}

.spacing {
  margin: var(--spacing-3) 0;
}

.table-bottom {
  margin-left: auto;
}
</style>