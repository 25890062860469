import moment from 'moment'
import auth0 from '../plugins/auth0'
import { COMMUNITIES, GAB, GETTR, BITCHUTE, MEWE, WIMKIN, RUMBLE, MINDS, LBRY, VK, TIKTOK, RUTUBE, OK, BLUESKY, TRUTH_SOCIAL_CRAWL_REQUESTS } from '../constants/sites'
import { KEYWORDS, PROFILES, CHANNELS } from '../constants/crawlRequests'

export function toCrawlType (type) {
  return {
    [KEYWORDS]: 'keyword',
    [PROFILES]: 'profile',
    [CHANNELS]: 'telegram',
  }[type]
}

export const sites = [
  {
    name: COMMUNITIES,
    label: 'Communities',
  },
  {
    name: GETTR,
    label: 'Gettr',
  },
  {
    name: BITCHUTE,
    label: 'Bitchute',
  },
  {
    name: MEWE,
    label: 'MeWe',
  },
  {
    name: WIMKIN,
    label: 'Wimkin',
  },
  {
    name: RUMBLE,
    label: 'Rumble',
  },
  {
    name: MINDS,
    label: 'Minds',
  },
  {
    name: LBRY,
    label: 'LBRY',
  },
  {
    name: VK,
    label: 'VK',
  },
  {
    name: TRUTH_SOCIAL_CRAWL_REQUESTS,
    label: 'Truth Social',
  },
  {
    name: TIKTOK,
    label: 'TikTok',
  },
  {
    name: RUTUBE,
    label: 'RUTUBE',
  },
  {
    name: GAB,
    label: 'Gab',
  },
  {
    name: OK,
    label: 'OK',
  }
]

export function siteLabel (siteName) {
  return sites.find(site => site.name === siteName).label
}

export async function apiFetch ({ params, fetchType }) {
  const token = await auth0.getAccessTokenSilently()
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }

  const url = new URL(fetchType === 'timeseries'
    ? 'https://api.openmeasures.io'
    : 'https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev')

  if (fetchType === 'get') {
    url.pathname = 'get_crawl_requests'
  } else if (fetchType === 'timeseries') {
    url.pathname = 'timeseries'
  } else if (fetchType === 'update') { // update or delete
    url.pathname = 'update_crawl_request'
  } else if (fetchType === 'add') {
    url.pathname = 'crawl_request'
  } else {
    throw Error('specify fetch type')
  }

  url.search = new URLSearchParams(params)

  const response = await fetch(
    url.href, {
      headers,
      method: fetchType === 'get' || fetchType === 'timeseries'
        ? 'GET'
        : 'POST'
    })

  try {
    return await response.json()
  } catch (err) {
    // if the fetch doesn't return any data (e.g. on POST) then json() throws
    if (err instanceof SyntaxError) return
    throw err
  }
}

export async function timeseriesFetch ({ term, site, sinceLast }) {
  if (site === TRUTH_SOCIAL_CRAWL_REQUESTS) {
    site = 'truth_social'
  } else if (site === BITCHUTE) {
    site = 'bitchute_video'
  } else if (site === RUTUBE) {
    site = 'rutube_video'
  } else if (site === TIKTOK) {
    site = 'tiktok_video'
  } else if (site === RUMBLE) {
    site = 'rumble_video'
  } else if (site === LBRY) {
    site = 'lbry_video'
  } else if (site === COMMUNITIES) {
    site = 'win'
  } else if (site === BLUESKY) {
    return null
  }

  let since
  if (sinceLast === 'day') {
    since = moment().subtract(1, 'days').format('YYYY-MM-DD')
  } else if (sinceLast === 'week') {
    since = moment().subtract(7, 'days').format('YYYY-MM-DD')
  } else if (sinceLast === 'month') {
    since = moment().subtract(30, 'days').format('YYYY-MM-DD')
  } else if (sinceLast === 'year') {
    since = moment().subtract(365, 'days').format('YYYY-MM-DD')
  }
  const until = moment().format('YYYY-MM-DD')

  const params = {
    since: since,
    until: until,
    site: site
  }
  if (site === 'telegram') {
    params.term = 'channelusername: ' + term
    params.esquery = 'true'
  } else {
    params.term = term
  }

  return await apiFetch({ params, fetchType: 'timeseries' })
}