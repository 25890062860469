<template>
  <div class="top-left">
    <FatButton @click="addModalOpen = true" class="add-button">
      <div class="button-content">
        Add {{ type.toUpperCase() }} <BaseIcon name="add"/>
      </div>
    </FatButton>
    <AddModal
      v-model="addModalOpen"
      :type="type"
      :requests-available="requestsAvailable"
      @adding="$emit('adding')"
      @added="$emit('added')"
    />
    <q-linear-progress
      size="22px"
      :value="percentage"
      color="our-light-text"
      instant-feedback
    >
      <BaseTooltip>
        Number of used requests for this request type. Requests only count toward the maximum if they are active.
      </BaseTooltip>
      <div class="absolute-full flex flex-center">
        <q-badge
          color="white" text-color="black" :label="badgeLabel"></q-badge>
      </div>
    </q-linear-progress>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import BaseTooltip from '../BaseTooltip.vue'
import FatButton from '../input/FatButton.vue'
import BaseIcon from '../BaseIcon.vue'
import AddModal from './AddModal.vue'

const {
  mapGetters,
} = createNamespacedHelpers('crawlRequests')

export default {
  components: {
    FatButton,
    BaseIcon,
    BaseTooltip,
    AddModal,
  },
  data () {
    return {
      addModalOpen: false,
    }
  },
  computed: {
    ...mapGetters(['type', 'requestsAvailable']),
    percentage () {
      if (!this.requestsAvailable) return 0
      return this.requestsAvailable.used / this.requestsAvailable.total
    },
    badgeLabel () {
      if (!this.requestsAvailable) return 'Loading…'
      return `${this.requestsAvailable.used} / ${this.requestsAvailable.total}`
    },
  },
}
</script>

<style scoped lang="scss">
.add-button {
  margin-bottom: var(--spacing-1);
}

.button-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-0);
}
</style>