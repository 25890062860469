<template>
  <q-btn-toggle
    class="button-toggle"
    no-caps
    unelevated
    toggle-color="our-blue"
    toggle-text-color="our-black"
    color="white"
    text-color="our-black"
    :model-value="type"
    @update:model-value="update"
    :options="types.map(type => ({
      value: type,
      label: $t(`crawl.types.${type}`),
    }))"
  >
  </q-btn-toggle>
</template>

<script>
import { KEYWORDS, PROFILES, CHANNELS } from '../../constants/crawlRequests'
import { CRAWLREQUESTS } from '../../constants/tools'

export default {
  data () {
    return {
      types: [
        KEYWORDS,
        PROFILES,
        CHANNELS,
      ],
      CRAWLREQUESTS,
    }
  },
  computed: {
    type () {
      return this.$store.getters['crawlRequests/type']
    }
  },
  methods: {
    update (event) {
      this.$router.push(`/${CRAWLREQUESTS}/${event}`)
      this.$emit('newTab', event)
    },
  },
}
</script>

<style scoped lang="scss">
.button-toggle :deep(button) {
  border: 1px solid var(--color-blue);
}
</style>